import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    arrow: true,
    infinite: true,
    speed: 4700,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    margin: 30,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      imageName: "review-lynn",
      desc: `Els neemt haar tijd om je paard correct op te meten en om te kijken hoe het zadel ligt bij het rijden. Ze luistert ook naar je wensen als klant. Heel tevreden met mijn nieuwe zadelpasser!`,
      reviewerName: "Lynn Evers"
    },
    {
      imageName: "review-emy",
      desc: `I recommend! I finally found a serious and competent person who takes the time to research, analyze and find the saddle that suits the rider and the horse. This is not the case with so-called other saddle fitters.`,
      reviewerName: "Emilie Degee"
    },
    {
      imageName: "review-sarah",
      desc: `Heel tevreden, ondanks dat het paard een grote boom nodig had, toch een passend zadel gevonden waar hij heel fijn mee loopt. Els denkt mee over hoe het paard gereden wordt en hoe de rug kan veranderen door de intensiviteit van de training doorheen het jaar. Dit met resultaat dat het zadel perfect bij onze noden past.`,
      reviewerName: "Sarah Estercam"
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div
            className="testmonial-box media"
            key={i}
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <div className="avatar">
              <img
                src={`img/testimonial/${val.imageName}.jpg`}
                alt="review comments"
              ></img>
            </div>

            {/* End avatar */}

            <div className="testmonial-text media-body">
              <p>{val.desc}</p>
              <div className="testmonial-info">
                <h6>- {val.reviewerName}</h6>
              </div>
            </div>

            {/* End testimonial-text */}
          </div>
        ))}
      </Slider>
    </>
  );
}
