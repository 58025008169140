import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const AllPortfolioContent = [
  {
    img: "Mercier_Zadelmakerij_1",
    title: "",
    subTitle: "",
    alterText: "Zadelmakerij tools",
    delayAnimation: "",
  },
  {
    img: "Mercier_Zadelpas_2",
    title: "",
    subTitle: "",
    alterText: "Paard blij met zadelpasser",
    delayAnimation: "0",
    portfolioLink:
      "",
  },
  {
    img: "Mercier_Zadelmakerij_2",
    title: "",
    subTitle: "",
    alterText: "Zadelmakerij wol",
    delayAnimation: "100",
    portfolioLink:
      "",
  },
  {
    img: "Mercier_Zadelpas_1",
    title: "",
    subTitle: "",
    alterText: "Skelet paard",
    delayAnimation: "200",
    portfolioLink:
      "",
  },
  {
    img: "Mercier_Zadelpas_3",
    title: "",
    subTitle: "",
    alterText: "Zadel opmeten paard",
    delayAnimation: "100",
    portfolioLink:
      "",
  },
  {
    img: "Mercier_Zadelpas_4",
    title: "",
    subTitle: "",
    alterText: "Zadel opmeten paard",
    delayAnimation: "200",
    portfolioLink:
      "",
  },
];

const PortfolioAnimation = () => {
  return (
    <SimpleReactLightbox>
      <div className="positon-relative">
        <div className="portfolio-filter-01">
            <SRLWrapper>
                <div className="portfolio-content row lightbox-gallery">
                  {AllPortfolioContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5>
                              <a href={val.portfolioLink} target="_blank" rel="noreferrer">
                                {val.title}
                              </a>
                            </h5>
                            <span>{val.subTitle}</span>
                          </div>
                          {/* End .portfolio-info */}
                          <a
                            href={`img/portfolio/${val.img}.jpg`}
                            className="gallery-link"
                          >
                            <img
                              src={`img/portfolio/${val.img}.jpg`}
                              alt={val.alterText}
                            />
                          </a>
                          {/* End gallery link */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper */}
            </SRLWrapper>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default PortfolioAnimation;
