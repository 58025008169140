import React from "react";
import Readmore from "../../components/Readmore";

const ServiceContent = [
  {
    icon: "ti-ruler-alt",
    no: "",
    service: "ZADELPASCONSULT",
    title: "Analyse & Advies",
    shortdesc: `Na een afspraak kom ik langs om het paard te controleren, op te meten en het huidige zadel te controleren. Na het doorlopen van onderstaande stappen kan er dan gericht advies gegeven worden...`,
    longdesc: `<p>Na een afspraak kom ik langs om het paard te controleren, op te meten en het huidige zadel te controleren. Na het doorlopen van onderstaande stappen kan er dan gericht advies gegeven worden.</p><ol type='1'><li>Kennismaking paard en klant hierbij wordt er zoveel mogelijk informatie verkregen over het paard zoals ziektegeschiedenis, huisvesting, trainingsregime, eventuele problemen,…</li><li>Signalement en conformatie: hier wordt er naar de bouw van het paard gekeken omdat dit mede bepalend is voor de ligging van een zadel.</li>  
                <li>Onderzoek van de rug, schouders en singelgroeven.</li>
                <li>Maken van een template (opmeten van het paard): hierdoor kan er bepaald worden welke boom en boommaat een paard nodig heeft.</li>
                <li>Monsteren: beoordelen van het paard in beweging op harde ondergrond.</li>
                <li>Controle van het huidige zadel op zadel gebreken.</li>
                <li>Beoordeling van de ligging van het huidige zadel onbelast en met de ruiter.</li>
                <li>Conclusie en advies</li>
              </ol><p>Indien nodig kunnen wolgevulde zadels ter plaatse uitgebalanceerd worden en kopijzers verwisseld worden (Tekna, Ikonic, Wintec, Kent&Master, Thorowgood en fairfax)</p>`,
    animationDelay: "",
  },
  {
    icon: "ti-layout",
    no: "",
    service: "ZADELVERKOOP",
    title: "Nieuw & Tweedehands",
    shortdesc: `Ben je toe aan een nieuw zadel? Mercier Saddlefit heeft heel wat demo zadels ter beschikking zodat we samen op zoek kunnen gaan naar het best passende zadel voor paard en ruiter...<br/>`,
    longdesc: `Ben je toe aan een nieuw zadel? Mercier Saddlefit heeft heel wat demo zadels ter beschikking zodat we samen op zoek kunnen gaan naar het best passende zadel voor paard en ruiter. <br/><br/>De volgende merken zijn nieuw aan te kopen: 
    Ikonic, Esta (MFSC), Tekna, GFS, Kent &amp; Master, Fairfax, Thorowgood, Anatomica, Jeffries en Harry Dabbs. Er wordt altijd binnen het budget van de klant gezocht naar een passend zadel. Verder wordt er ook advies gegeven zodat de klant zelf op zoek kan gaan op de tweedehands markt. 
    `,
    animationDelay: "150",
  },
  {
    icon: "ti-image",
    no: "",
    service: "ZADELMAKERIJ",
    title: "Allerlei herstellingen",
    shortdesc: `In de zadelmakerij kan je terecht voor herstellingen aan je zadel zoals het vernieuwen van de wol vulling, vervangen van singelstoten, vervangen van een klinknagel, vastzetten van stiksels en het verbreden of versmallen van de boom (koude pers).`,
    longdesc: `In de zadelmakerij kan je terecht voor herstellingen aan je zadel zoals het vernieuwen van de wol vulling, vervangen van singelstoten, vervangen van een klinknagel, vastzetten van stiksels en het verbreden of versmallen van de boom (koude pers).`,
    animationDelay: "300",
  }
];

const ServiceTwoAnimation = () => {
  return (
    <>
      <div className="row gy-4">
        {ServiceContent.map((val, i) => (
          <div
            className="col-sm-6 col-lg-4"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.animationDelay}
          >
            <div className="feature-box-02">
              <h6>
                <span>{val.no}</span> {val.service}
              </h6>
              <h5>{val.title}</h5>
              <Readmore shortdesc={val.shortdesc} longdesc={val.longdesc}></Readmore>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ServiceTwoAnimation;
