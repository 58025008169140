import React, { useState } from "react";

const Readmore = props => {

    const [showMore, setShowMore] = useState(false);
    const shortdesc =  props.shortdesc;
    const longdesc =  props.longdesc;

    const getText = () => {
        // For Text that is shorter than desired length
        if (longdesc.length <= 258) return longdesc;
        // If text is longer than desired length & showMore is true
        if (longdesc.length > 258 && showMore) {
          return (
            <>
            <p
              dangerouslySetInnerHTML={{
                __html: longdesc
              }}>
            </p><br/>
              <button className="px-btn px-btn-theme btn-md"
                onClick={() => setShowMore(false)}>
                Lees minder
              </button>
            </>
          );
        }
         // If text is longer than desired length & showMore is false
        if (longdesc.length > 258) {
          return (
            <>
              <p
                dangerouslySetInnerHTML={{
                  __html: shortdesc
                }}>
              </p><br/>
              <button className="px-btn px-btn-theme btn-md"
                onClick={() => setShowMore(true)}>
                Lees meer
              </button>
            </>
          );
        }
      };

    return (
      <div>
            {getText()}
      </div>
    );
  };
  
  export default Readmore;