import React from "react";

const Why = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-10 my-3">
          <p>Het doel van een zadel is het gewicht van de ruiter gelijkmatig te verdelen zodat de druk op de paardenrug kleiner wordt. Hier loopt het vaak fout. Bij een slecht passend zadel gaat het paard na verloop van tijd subtiele gedragsveranderingen vertonen zoals:</p> 
          <ul>
            <li>Niet willen stilstaan bij het opstijgen </li>
            <li>Zadel- en singelnijd</li>
            <li>Niet meer ontspannen rijden en wat kan leiden tot verzet tijdens het rijden </li>
          </ul>
          <p>Door deze subtiele gedragsveranderingen te negeren gaan er uiteindelijk zichtbare problemen ontstaan zoals:</p> 
          <ul>
            <li>Zwellingen</li>
            <li>Spierverlies achter de schoft en rug regio</li>
            <li>Witte haren op de rug</li>
            <li>Kreupelheid en pijn</li>
            <li>Drukplekken of wondjes</li>
          </ul>
          <p>De tijd van eender welk zadel op een paard te leggen is al lang voorbij. We weten nu wel beter. Paarden zijn lieve, gewillige en betrouwbare dieren en geven ons zoveel plezier. Daarom moeten wij als eigenaar ervoor zorgen dat we paarden in de meest optimale en paardvriendelijke omstandigheden houden. Een goed passend zadel is daar één onderdeel van.</p>
        </div>
      </div>
    </>
  );
};

export default Why;
