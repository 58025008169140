import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/footer/FooterAnimation";
import useDocumentTitle from "../components/useDocumentTitle";

const NotFound = () => {
  useDocumentTitle("Mercier Saddlefit - 404");
  return (
    <>
      <section className="error-page-wrapper">
        <div className="container">
          <div className="row justify-content-center full-screen align-items-center">
            <div className="col-lg-8 text-center">
              <div className="inner">
                <h1 className="display-3 white-color m-15px-b">
                  Pagina niet gevonden..
                </h1>
                <p className="h4">
                  Oeps, de pagina die je zocht bestaat niet (meer).
                </p>
                <div className="m-30px-tb">
                  <img src="img/404-page.svg" alt="page not found" />
                </div>
                <div className="btn-bar mt-4">
                  <Link className="px-btn px-btn-theme" to="/">
                    Terug naar startpagina
                  </Link>
                  {/* End purchase_button */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Error Page Wrapper */}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/* End footer */}
    </>
  );
};

export default NotFound;
