import React from "react";

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div
          className="col-lg-6 my-3"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="img-box">
            <img src="img/about/mercier-intro.jpg" alt="Els Mercier zadelpasser" />
          </div>
        </div>

        <div
          className="col-lg-6 my-3"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="typo-box about-me">
            <h3>Els Mercier</h3>
            <h5>
              Paarden, mijn <span className="color-theme">passie</span>
            </h5>
            <p>
              Ondertussen ben ik al meer dan 30 jaar actief in de paardenwereld. Vaak deelgenomen aan competities (dressuur, springen en eventing), maar tegenwoordig geniet ik vooral van het recreatief omgaan met mijn paarden.
            </p>
            <p>
              Door de jaren heen ben ik regelmatig paarden tegengekomen die ongemak ervaren door een slecht passend zadel, waaronder mijn eigen paard. Dit heeft me gemotiveerd om me verder te verdiepen in de anatomie &amp; gedrag van paarden en zadels, waarna ik de opleiding tot Master Saddle Fitting (MSFC) consultant heb vervolledigd. Het geeft me telkens een gelukzalig gevoel om een paard blij te mogen maken met een kwalitatief, goed passend zadel.
            </p>
            {/* End .row */}
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
