import React from "react";
import TextLoop from "react-text-loop";
import Social from "../Social";

const Slider = () => {
  return (
    <>
      {/*  Home Banner */}
      <section
        id="home"
        className="home-banner video-banner slider-three-animation"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "img/banner/bg-4.jpg"
          })`,
          backgroundPosition: 'left'
        }}
      >
        <div className="frame-layout__particles"></div>
        {/* End particle animation */}
        <div className="container">
          <div className="row align-items-center full-screen">
            <div className="col-md-12 col-lg-8">
              <div className="hb-typo text-center">
                <h1
                  className="font-alt"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <img
                className="logo"
                src="img/logo/logo-f.png"
                width="60%"
                alt="Logo"
              />
                </h1>

                  <h2
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="100"
                  >
                    <TextLoop>
                      <span className="loop-text">Gediplomeerd zadelpasser (MSFC)</span>
                      <span className="loop-text"> Verkoop zadels</span>
                      <span className="loop-text"> Zadelmakerij</span>
                    </TextLoop>{" "}
                  </h2>
                  <div className="intro col-md-12 col-lg-8">
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="200"
                  >
                    Zoek je een gediplomeerde zadelpasser of zadelmaker? Mercier Saddlefit biedt volgende diensten aan: <ul><li> Zadelpasconsult</li><li> Zadelverkoop (nieuw &amp; tweedehands)</li><li> Zadelmakerij</li></ul>
                  </div>
                </div>
                <div className="btn-bar">
                  <a className="px-btn px-btn-theme btn-md" href="#about">
                    Lees meer
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End .container */}

        {/* End bottom scroll */}

        <Social />
        {/* End social slide  */}
      </section>
      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
