import React from "react";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/SliderThreeAnimation";
import About from "../../components/about/AboutLightAnimation";
import Service from "../../components/service/ServiceTwoAnimation";
import Portfolio from "../../components/portfolio/PortfolioAnimation";
import Testimonial from "../../components/testimonial/TestimonialAnimation";
import Footer from "../../components/footer/FooterAnimation";
import useDocumentTitle from "../../components/useDocumentTitle";
import Why from "../../components/why/Why";
import Social from "../../components/Social";

const HomeThree = () => {
  useDocumentTitle("Mercier Saddlefit");
  return (
    <>
      <Header />
      {/* End Header Section */}

      <Slider />
      {/* End Banner Section */}

      {/* About Me */}
      <section id="about" className="section about-section after-left-section">
        <div className="container">
          <About />
        </div>
      </section>
      {/* End About Me */}

      {/* Why */}
      <section id="why" className="section why-section">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Goed passend zadel</h3>
              <p>
              </p>
            </div>
          </div>
          {/* End .row */}
          <Why />
        </div>
      </section>
      {/* Why */}

      {/* Services */}
      <section id="services" className="section services-section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Diensten</h3>
              <p>
              </p>
            </div>
          </div>
          {/* End .row */}
          <Service />
        </div>
      </section>
      {/* End Services */}

      <section id="work" className="section">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Galerij</h3>
            </div>
          </div>
          {/* End .row */}
          <Portfolio />
        </div>
      </section>
      {/* End Portfolio */}

      <div className="section testimonial gray-bg">
        <div className="container">
        <div className="row justify-content-center section-title text-center">
        <div className="col-lg-12">
                <h3 className="font-alt">Klanten aan het woord</h3>
                <Testimonial />
        </div>
      </div>
          
        </div>
      </div>
      {/* End testimonial */}

      {/* Contact */}
      <section id="contact" className="section after-left-section">
        <div className="container">
          <div className="row align-items-center section-title text-center">
            <div className="col-lg-8 my-3">
              <div className="contct-form contact-info">
                  <h3 className="font-alt">Contactgegevens</h3>
                  <div className="contact-info-intro">
                    <div>Met veel plezier help ik jou en je paard verder!</div><div>Wil je een afspraak maken, of wens je meer informatie?</div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 my-3">
                        <div><b>Mercier Saddlefit</b></div>
                        <div>3400 Overwinden, België</div>
                        <div>BTW BE0772937669</div><br/>
                        <div>
                          <span className="">
                            <i className="ti-mobile"></i>&nbsp;&nbsp;
                          </span>
                          <span className="">
                            <a href="tel:+32497029934">+32497 02 99 34</a>
                            <br/>(ook bereikbaar via Whatsapp)
                          </span>
                          <div>
                            <span className="">
                              <i className="ti-email"></i>&nbsp;&nbsp;
                            </span>
                            <span className="">
                              <a href="mailto:els@mercier-saddlefit.be">els@mercier-saddlefit.be</a>
                            </span>
                          </div>
                      </div>
                    </div>
                    <div className="col-lg-6 my-3">
                      <div><b>Volg me op sociale media</b></div>
                      <Social/>
                      <div>
                      </div>
                    </div>
                  </div>
                {/* End .sm-title */}
              </div>
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
      {/* End Contact */}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/*  End Footer */}
    </>
  );
};

export default HomeThree;
